require('./bootstrap');

import * as bootstrap from 'bootstrap';

// Assign bootstrap to window variable
window.bootstrap = bootstrap;

import $ from 'jquery';

window.$ = window.jQuery = $;

// Enable tooltips
var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl)
})

if (document.querySelector('#evaluation_year')) {
    // Filter evaluations
    window.evaluationFilterChanged = function () {
        var year = document.querySelector('#evaluation_year').value;
        var department = document.querySelector('#evaluation_department').value;
        var evaluation_user = document.querySelector('#evaluation_user').value.toLowerCase();
        var evaluations = document.querySelectorAll('#evaluation[data-year]');

        evaluations.forEach(function (evaluation) {
            if ((year === '' || evaluation.getAttribute('data-year') === year) &&
                (department === '' || evaluation.getAttribute('data-department-id') === department) &&
                (evaluation_user === '' || evaluation.getAttribute('data-user').toLowerCase().indexOf(evaluation_user) !== -1)) {
                evaluation.removeAttribute('hidden');
            } else {
                var checkbox = evaluation.querySelector('input[type="checkbox"]');
                if (checkbox) {
                    checkbox.checked = false;
                }
                evaluation.setAttribute('hidden', '');
            }
        });
    }
    // Reset evaluations on initial load
    evaluationFilterChanged();
}

window.expectationChanged = function (appraisalExpectation) {
    var tdElement = document.getElementById('expectation' + appraisalExpectation);  // Get the TD element
    var selectElement = tdElement.querySelector("select");  // Get the select element inside the TD
    var selectedOption = selectElement.options[selectElement.selectedIndex];  // Get the selected option
    var color = selectedOption.getAttribute("data-color");  // Get the value of the data-color attribute
    var expectation = selectedOption.value;  // Get the value of the selected option
    var tokenInput = document.querySelector('input[name="_token"]');
    var tokenValue = tokenInput.value;
    var errorAlert = document.getElementById('error');  // Get the alert element

    tdElement.className = 'table-' + color; // Change color of TD

    var url = "/update_expectation";
    var params = "appraisal_expectation_id=" + appraisalExpectation + "&expectation_id=" + expectation;

    fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
            'X-CSRF-TOKEN': tokenValue
        },
        body: params
    })
        .then(response => {
            if (!response.ok) {
                throw new Error(response.statusText); // Throw error for non-successful response
            }
            return response.json();
        })
        .then(responseData => {
            if (!responseData.success) {
                errorAlert.innerText = responseData.data; // Set the error message as plain text
                errorAlert.classList.remove("collapse"); // Remove the "collapse" class to make the error message visible
            } else {
                errorAlert.classList.add("collapse");
            }
        })
        .catch(error => {
            errorAlert.innerText = error.message; // Set the error message as plain text
            errorAlert.classList.remove("collapse"); // Remove the "collapse" class to make the error message visible
        });
}
